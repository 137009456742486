import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import _objectSpread from "@babel/runtime/helpers/esm/objectSpread";
import algoliasearchHelper from 'algoliasearch-helper';
import createConnector from '../core/createConnector';
import { omit, getObjectType, removeEmptyKey, removeEmptyArraysFromObject } from '../core/utils';
import { refineValue, getIndexId, hasMultipleIndices } from '../core/indexUtils';

function createOptionalFilter(_ref) {
  var attributeName = _ref.attributeName,
      attributeValue = _ref.attributeValue,
      attributeScore = _ref.attributeScore;
  return "".concat(attributeName, ":").concat(attributeValue, "<score=").concat(attributeScore || 1, ">");
}

var defaultProps = {
  transformSearchParameters: function transformSearchParameters(x) {
    return _objectSpread({}, x);
  }
};

function getId() {
  // We store the search state of this widget in `configure`.
  return 'configure';
}

function getSearchParametersFromProps(props) {
  var optionalFilters = Object.keys(props.matchingPatterns).reduce(function (acc, attributeName) {
    var attributePattern = props.matchingPatterns[attributeName];
    var attributeValue = props.hit[attributeName];
    var attributeScore = attributePattern.score;

    if (Array.isArray(attributeValue)) {
      return [].concat(_toConsumableArray(acc), [attributeValue.map(function (attributeSubValue) {
        return createOptionalFilter({
          attributeName: attributeName,
          attributeValue: attributeSubValue,
          attributeScore: attributeScore
        });
      })]);
    }

    if (typeof attributeValue === 'string') {
      return [].concat(_toConsumableArray(acc), [createOptionalFilter({
        attributeName: attributeName,
        attributeValue: attributeValue,
        attributeScore: attributeScore
      })]);
    }

    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.warn("The `matchingPatterns` option returned a value of type ".concat(getObjectType(attributeValue), " for the \"").concat(attributeName, "\" key. This value was not sent to Algolia because `optionalFilters` only supports strings and array of strings.\n\nYou can remove the \"").concat(attributeName, "\" key from the `matchingPatterns` option.\n\nSee https://www.algolia.com/doc/api-reference/api-parameters/optionalFilters/"));
    }

    return acc;
  }, []);
  return props.transformSearchParameters(new algoliasearchHelper.SearchParameters({
    // @ts-ignore @TODO algoliasearch-helper@3.0.1 will contain the type
    // `sumOrFiltersScores`.
    // See https://github.com/algolia/algoliasearch-helper-js/pull/753
    sumOrFiltersScores: true,
    facetFilters: ["objectID:-".concat(props.hit.objectID)],
    optionalFilters: optionalFilters
  }));
}

export default createConnector({
  displayName: 'AlgoliaConfigureRelatedItems',
  defaultProps: defaultProps,
  getProvidedProps: function getProvidedProps() {
    return {};
  },
  getSearchParameters: function getSearchParameters(searchParameters, props) {
    return searchParameters.setQueryParameters(getSearchParametersFromProps(props));
  },
  transitionState: function transitionState(props, _prevSearchState, nextSearchState) {
    var id = getId(); // We need to transform the exhaustive search parameters back to clean
    // search parameters without the empty default keys so we don't pollute the
    // `configure` search state.

    var searchParameters = removeEmptyArraysFromObject(removeEmptyKey(getSearchParametersFromProps(props)));
    var searchParametersKeys = Object.keys(searchParameters);
    var nonPresentKeys = this._searchParameters ? Object.keys(this._searchParameters).filter(function (prop) {
      return searchParametersKeys.indexOf(prop) === -1;
    }) : [];
    this._searchParameters = searchParameters;

    var nextValue = _defineProperty({}, id, _objectSpread({}, omit(nextSearchState[id], nonPresentKeys), searchParameters));

    return refineValue(nextSearchState, nextValue, {
      ais: props.contextValue,
      multiIndexContext: props.indexContextValue
    });
  },
  cleanUp: function cleanUp(props, searchState) {
    var _this = this;

    var id = getId();
    var indexId = getIndexId({
      ais: props.contextValue,
      multiIndexContext: props.indexContextValue
    });
    var subState = hasMultipleIndices({
      ais: props.contextValue,
      multiIndexContext: props.indexContextValue
    }) && searchState.indices ? searchState.indices[indexId] : searchState;
    var configureKeys = subState && subState[id] ? Object.keys(subState[id]) : [];
    var configureState = configureKeys.reduce(function (acc, item) {
      if (!_this._searchParameters[item]) {
        acc[item] = subState[id][item];
      }

      return acc;
    }, {});

    var nextValue = _defineProperty({}, id, configureState);

    return refineValue(searchState, nextValue, {
      ais: props.contextValue,
      multiIndexContext: props.indexContextValue
    });
  }
});